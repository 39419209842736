<template>
    <div class="vip-content" style="background-color: #F9FAFA;">
        <div style="width: 90%;margin: 0 auto;height: 182px;">
            <div v-if="allNum==0">
                <div style="height: 44px;background-color:#EBF0F2;text-align: center;line-height: 44px;">当前未开通超值套餐</div>
                <div @click="goBuy()"
                    style="height: 138px;text-align: center;line-height: 138px;color:#0079F9;background-color: #FFFFFF;cursor: pointer;font-weight: 400;">
                    立即前往开通 >></div>
            </div>
            <div v-else>
                <div
                    style="display: inline-block; width: 49%;margin-right: 2%; background-color: #FFA055;height: 182px;vertical-align: top;">
                    <div style="font-size:16px;color:white;margin-left:5%;margin-top:12px;">数据概览</div>
                    <div style="border-bottom: 1px solid white;width: 90%;margin-left: 5%;margin-top:10px;"></div>
                    <div style="font-size:14px;color:white;margin-left:5%;margin-top:12px;position: relative;">
                        <div style="position: absolute;top:12px;">套餐总数：{{allNum}}</div>
                        <div style="position: absolute;top:12px;left:33%;">累计授权：{{allAuth}}</div>
                        <div style="position: absolute;top:12px;left:66%;">剩余授权： {{allExAuth}}</div>
                        <div style="position: absolute;top:42px;">已到期数：{{allExNum}}</div>
                        <div style="position: absolute;top:42px;left:33%;">累计下载：{{allDl}}</div>
                        <div style="position: absolute;top:42px;left:66%;">剩余下载： {{allExDl}}</div>    	   	 	  
                    </div>
                    

                </div>
                <div
                    style="display: inline-block;width: 49%;background-color: #FFA055;height: 182px;vertical-align: top;">
                    <div style="font-size:16px;color:white;margin-left:5%;margin-top:12px;width:90%;">{{newData.goods_name}}
                    </div>
                    <div style="border-bottom: 1px solid white;width: 90%;margin-left: 5%;margin-top:10px;"></div>
                    <div style="font-size:14px;color:white;margin-left:5%;margin-top:12px;position: relative;">
                        <div style="position: absolute;top:12px;">套餐类型：{{newData.type_str}}</div>
                        <div style="position: absolute;top:12px;left:33%;">剩余授权：{{newData.unauth_num}}</div>
                        <div style="position: absolute;top:12px;left:66%;">剩余下载：{{newData.undownload_num}}</div>
                        <div style="position: absolute;top:42px;">授权范围：{{newData.goods_cate}}-{{newData.use_mode}}-{{newData.auth_area | toArea}}-{{newData.auth_time | toAuthPeriod}}</div>	 	  	 	   
                    </div>
                    <div style="font-size:8px;color:#333333;margin-left:5%;margin-top:90px;">
                        <span style="color:#EF3131">*</span>
                        <span >当前展示数据为最新开通套餐，历史套餐数据请查看下方【我的套餐】列表</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="cont-header">
                <span>开通记录</span>
            </div> -->
        <div class="cont">
            <div style="height: 60px;text-align: center;line-height: 60px;background-color: #FFFFFF;">
                <div
                    style="height: 60px;line-height: 60px;color:#FF7B13;width:64px;margin-left:18px;border-bottom: 1px solid #FF7B13;">
                    购买记录</div>

            </div>
            <a-row v-for="(scope, key) in dataTableList" :key="key" style="margin-top: 16px;">
                <a-col :span="24" class="list-header"
                    style="line-height:40px;background: #F4F6F6;border: 1px solid #EBF0F2;padding-left: 1%;height:40px;">
                    <a-col span="3"><span>商品类型</span></a-col>
                    <a-col span="3"><span>套餐名称</span></a-col>
                    <a-col span="3"><span>套餐类型</span></a-col>
                    <a-col span="5"><span>授权范围</span></a-col>
                    <a-col span="3"><span>剩余授权</span></a-col>
                    <a-col span="3"><span>剩余下载</span></a-col>
                    <a-col span="4"><span>到期时间</span></a-col>
                </a-col>
                <a-col :span="24"
                    style="height:142px;border: 1px solid #EBF0F2;display:flex;align-items:center;padding-left: 1%;background-color: #FFFFFF;">
                    <a-col :span="3">
                        <div style="width: 82px;
                        height: 34px;text-align: center;line-height: 34px;color:white;
                        background: linear-gradient(132deg, #73A2BE 0%, #578AA9 100%);
                        border-radius: 6px;">超值套餐</div>
                    </a-col>
                    <a-col :span="3"><span>{{scope.goods_name}}</span></a-col>
                    <a-col :span="3"><span>{{scope.type_str}}</span></a-col>
                    <a-col :span="5" style="text-align:left; padding-top: 14px;">
                        <a-col :span="24" >
                            <p style="margin-bottom: 0;">授权数量：{{scope.auth_num}}首</p>
                            <p style="margin-bottom: 0;">授权用途：{{scope.use_mode}}</p>
                            <p style="margin-bottom: 0;">授权地域：{{scope.auth_area | toArea}}</p>
                            <p style="margin-bottom: 0;">授权期限：{{scope.auth_time | toAuthPeriod}}</p>
                        </a-col>
                    </a-col>
                    <a-col :span="3">{{scope.unauth_num}}首</a-col>
                    <a-col :span="3">{{scope.undownload_num}}首</a-col>
                    <a-col :span="4"><span>{{scope.expire_date}}</span></a-col>

                </a-col>
            </a-row>
            <a-row v-if="dataTableList.length == 0" style="text-align:center;padding-top:24px;">
                <img src="@/assets/images/empty.png" style="width:76px;" />
                <br /><span style="color:rgba(0, 0, 0, 0.25);">暂无数据</span>
            </a-row>
        </div>
        <!-- <auth @close='showAuth=false' v-if="showAuth"></auth> -->
    </div>
</template>
<script>
    import { Table as ATable } from 'ant-design-vue';
    // import auth from './auth.vue'
    export default {
        data() {
            return {
                
                dataTableList: [],
                type_list:['','官网直售','定制套餐'],
                newData:[],
                allNum:0,
                allExNum:0,
                allAuth:0,
                allExAuth:0,
                allDl:0,
                allExDl:0,
            }
        },
        components: {
            ATable,
        },
        created: function () {
            this.getData();
        },
        computed: {
            userInfo() {
                return Object.assign({}, this.$store.state.userInfo);
            },
        },
        methods: {
            goBuy(){
                this.$router.push({path:'/package?id=3'});
            },
            getData() {
                let params = {};
                this.$axios.Package_listInUser(params).then(res => {
                    const data = res.data;
                    if (data && data.code == 0) {
                        let list = data.data ? data.data.list : [];
                        if (list.length>0){
                            this.newData = list[0];
                        }
                        this.allNum = list.length;
                        this.allExNum = 0;
                        this.allAuth = 0;
                        this.allExAuth = 0;
                        this.allDl = 0;
                        this.allExDl = 0;
                        for(var j=0;j<list.length;j++){
                            var d = list[j];
                            d.type_str = this.type_list[d.type];
                            if (d.enable == 0){
                                this.allExNum += 1;
                            }
                            this.allAuth += parseInt(d.auth_num);
                            this.allExAuth += (parseInt(d.auth_num)-d.authed_num);
                            this.allDl += parseInt(d.download_num);
                            this.allExDl += (parseInt(d.download_num)-d.downloaded_num);
                        }
                        this.dataTableList = list;
                    }
                })
            },
            
            onChangePage(nowpage) {
                this.loading = true;
                let params = {
                    page: nowpage,
                }
                this.getTableData(params);
                this.pagination.current = nowpage;
                this.pagination = Object.assign({}, this.pagination);
            },
        },
    }
</script>
<style lang="scss" scoped>
    .cont-header {
        height: 66px;
        line-height: 66px;
        border-bottom: 1px solid #F0F4F5;

        span {
            display: inline-block;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            line-height: 22px;
            height: 22px;
        }

        span:nth-child(1) {
            width: 20%;
            padding-left: 32px;
        }

        span:nth-child(2) {
            cursor: pointer;
            width: 80%;
            color: #FF7B13;
            text-align: right;
            padding-right: 32px;
        }
    }

    .cont {
        width: 90%;
        margin: 0 auto;
        padding-top: 24px;
    }

    .auth-info {
        p {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 24px;
        }

        p:nth-child(4) {
            color: #333333;
        }
    }
</style>